import React, { useState, useEffect, act } from 'react';
import { useParams } from 'react-router-dom';
import { useKeyPress } from "../utils";
import { useScreen } from '../context';
import { Link } from 'react-router-dom';

import { eqsiData } from '../dummyData';
import { BasketItem, Supplier } from '../types';

import { fetchPurchaseOrder, fetchBulkProducts, createPurchaseOrder } from '../api';

import {  
  calculatePurchaseOrderBasketTotals, 
  removeItemFromBasket,
  changeProductQuantity,
  formatBasketForD3
} from './basketUtils';

import SupplierSearchModal from './suppliersAndCustomersSearchModal';
import ProductsSearchModal from './productsSearchModal';

const NewPurchase = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const [basket, setBasket] = useState([] as BasketItem[]);

  const [deliveryLocation, setDeliveryLocation] = useState("");
  const branches = ["Bournemouth Branch", "Hull Branch", "Sheffield Branch"];

  const [supplierData, setSupplierData] = useState(null as null | Supplier);
  const [basketStats, setBasketStats] = useState({ totalQuoted: 0, discount: 0, discountPercentage: 0 });
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [activeBasketItem, setActiveBasketItem] = useState(null as null | string);
  const [suppliersModalOpen, setSuppliersModalOpen] = useState(false);
  const [productsModalOpen, setProductsModalOpen] = useState(false);

  const [deliveryFee, setDeliveryFee] = useState(null as null | number);
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");

  const [orderData, setOrderData] = useState([] as any[]);

  const { setScreenId, navCollapsed, toggleNavCollapsed, setPageHasDirtyForm } = useScreen();
  setScreenId(`PO-${orderId || "NEW"}`)
  
  const clearEQSI = () => {
    setSupplierData(null);
    setBasket([]);
    setBasketStats({ totalQuoted: 0, discount: 0, discountPercentage: 0 });
    setActiveBasketItem(null);
  }

  const backAction = () => {

  }
  useKeyPress('F1', backAction);

  const upAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item: BasketItem) => item.product["PRODUCT.CODE"] === activeBasketItem);
      if (indexOfItem !== 0) setActiveBasketItem(basket[indexOfItem - 1].product["PRODUCT.CODE"]);
    }
  }
  useKeyPress('ArrowUp', upAction);

  const downAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item: BasketItem) => item.product["PRODUCT.CODE"] === activeBasketItem);
      if (indexOfItem !== basket.length - 1) setActiveBasketItem(basket[indexOfItem + 1].product["PRODUCT.CODE"]);
    }
  }
  useKeyPress('ArrowDown', downAction);

  useEffect(() => {
    async function fetchOrderData() {
      if (orderId === "NEW" || !orderId) return;
      let fetchedOrderData = await fetchPurchaseOrder(orderId);
      console.log(fetchedOrderData);
      let order = fetchedOrderData[0]
      setOrderData(order)
      console.log(order);

      if (order["PRODUCT.CODE"] && order["PRODUCT.CODE"].length > 0) {
        let products = await fetchBulkProducts(order["PRODUCT.CODE"]);
        let basketData = order["PRODUCT.CODE"].map((productCode: string, index: number) => {
          products[index]["Total Stock"] = parseInt(products[index]["BOURNEMOUTH"]) + parseInt(products[index]["HULL"]) + parseInt(products[index]["SHEFFIELD"]);
          return {
            product: products[index],
            quantity: parseInt(order["Order Quant."][index]),
            basketPrice: parseFloat(order["Price"][index]),
            tax: 0
          }
        });

        console.log(basketData)
        setBasket(basketData);
        console.log(products);
      }
    }

    fetchOrderData();
  }, [orderId]);

  useEffect(() => {
    if (basket.length == 0) {
      setBasketStats({ totalQuoted: 0, discount: 0, discountPercentage: 0 });
      return
    }

    setPageHasDirtyForm(true);
    setBasketStats(calculatePurchaseOrderBasketTotals(basket));
  }, [basket]);

  const attemptCreatePurchaseOrder = async () => {
    if (!supplierData || !deliveryLocation  || basket.length === 0) return;
    console.log("Creating Purchase Order...");
    const formattedBasket = await formatBasketForD3(basket);
    console.log(formattedBasket);

    const orderPayload = {
      "P.ORDER.NO": `${supplierData.ACCNO}-${Math.floor(1000 + Math.random() * 9000)}-${new Date().getTime()}`,
      "SUPPLIER.CODE": supplierData.ACCNO,
      "ORDER.QUANTITY": formattedBasket.productQuantities,
      "PRODUCT.CODE": formattedBasket.productCodes,
      "PRICE": formattedBasket.productPrices
    }

    const response = await createPurchaseOrder(orderPayload);
    console.log(response);
  }
  

  return (
    <div className="flex flex-col gap-6 items-start w-full">
      <ProductsSearchModal 
        basket={basket}
        setBasket={setBasket}
        setActiveBasketItem={setActiveBasketItem}
        activeBasketItem={activeBasketItem}
        modalIsOpen={productsModalOpen}
        setModalOpen={setProductsModalOpen}
        screen="purchase"
      />

      <SupplierSearchModal
        setData={setSupplierData}
        modalIsOpen={suppliersModalOpen}
        setModalOpen={setSuppliersModalOpen}
        dataSource='Suppliers'
      />

      <div className="flex w-full items-end gap-4 dashboard-card p-4">
        {orderId == "NEW" 
          ? <>
              <h2 className="font-semibold text-2xl">New Purchase Order</h2>

              <button
                className="btn btn-secondary ml-auto"
                onClick={() => {
                  clearEQSI();
                  window.location.href = "/purchasing";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-primary disabled:opacity-50"
                onClick={attemptCreatePurchaseOrder}
                disabled={basket.length === 0 || !supplierData || !deliveryLocation}
              >
                Create Purchase Order
              </button>
            </>
          : <>
            <div>
              <p className="uppercase font-semibold text-sm">Purchase Order</p>
              <h2 className="text-widget-blue font-bold text-2xl">{orderId}</h2>
            </div>


            <button
              className="btn btn-secondary ml-auto"
              onClick={() => {
                clearEQSI();
                window.location.href = "/purchasing";
              }}
            >
              Discard
            </button>

            <button
              className="btn btn-primary"
            >
              Create Purchase Order
            </button>
          </>}
      </div>

      <div className="grid md:grid-cols-2 gap-6 w-full">
        <div className="dashboard-card">
          <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
            <i className="fas fa-user"></i>
            <h3 className="font-semibold uppercase text-sm">Supplier</h3>
          </div>

          <div className="p-4">
            <div className="flex gap-2 items-center cursor-pointer" onClick={() => setSuppliersModalOpen(true)}>
              {supplierData
                ? <h2 className="font-light text-xl md:text-2xl">{supplierData.NAME}</h2>
                : <h2 className="font-semibold text-xl">Select a supplier</h2>
              }
              <i className="fas fa-chevron-down"></i>
            </div>
            {supplierData && 
              <h3 className="font-light text-sm">
                {typeof supplierData["ADDRESS"] === "string" 
                  ? supplierData["ADDRESS"] 
                  : supplierData["ADDRESS"]
                    .filter((address: string) => address.length > 0)
                    .join(", ")}
              </h3>
            }
          </div>
        </div>

        <div className="dashboard-card">
          <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
            <i className="fas fa-map-location"></i>
            <h3 className="font-semibold uppercase text-sm">Branch</h3>
          </div>

          <div className="p-4">
            <select 
              className="input text-xl text-light outline-none"
              value={deliveryLocation}
              onChange={(e) => setDeliveryLocation(e.target.value)}
            >
              <option value="" disabled>Select a branch</option>
              {branches.map((branch: string, index: number) => (
                <option key={index} value={branch}>{branch}</option>
              ))}
            </select>
            {deliveryLocation && 
              <div className="flex justify-between items-center gap-2">
                <h3 className="font-light">123 Any Street, {deliveryLocation.replace("Branch", "")}</h3>
              </div>
            }
          </div>

        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-6 w-full">
        <div className="col-span-2">
          <div className="dashboard-card bg-base-100">
            <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
              <i className="fas fa-shopping-basket"></i>
              <h3 className="font-semibold uppercase text-sm">Basket</h3>
            </div>
          {basket && basket.length > 0 && (
            <table className="w-full text-left">
              <thead>
                <tr className="text-sm uppercase">
                  <th className="pl-8 pb-2 pt-4">Product</th>
                  <th className="text-center pb-2 pt-4">Stock (Incoming)</th>
                  <th className="text-center pb-2 pt-4">Cost Price</th>
                  <th className="text-center pb-2 pt-4">Terms Discount</th>
                  <th className="text-center pb-2 pt-4">Line Val</th>
                  <th className="text-center pb-2 pt-4 pr-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {basket.map((item: BasketItem, index: number) => (
                  <tr 
                    key={index} 
                    className={`cursor-pointer border-t border-gray-200`}
                    onClick={() => setActiveBasketItem(item.product["PRODUCT.CODE"])}
                  >
                    <td className="flex items-end gap-4 pl-6 py-4">
                      <img src={item.product["PICT.FLAG"]} alt={item.product["PRODUCT.CODE"]} className="h-16 w-16 object-cover rounded-md border border-gray-200 rounded-md" />
                      <div className="pb-1">
                        <p className="text-sm font-semibold">{item.product.DESCRIPTION}</p>
                        <div className="flex gap-2 items-center justify-start mt-1">
                          <div className="flex rounded border border-gray-300 bg-white items-center justify-start">
                            <div className="border-gray-300 border-r px-1">
                              <p className="text-sm">£</p>
                            </div>
                            <div className="px-1">
                              <input 
                                type="number" 
                                value={item.basketPrice} 
                                style={{ width: `${item.basketPrice.toString().length * 9 + 25}px` }}
                                onChange={(e) => {
                                  const updatedBasket = [...basket];
                                  updatedBasket[index].basketPrice = parseFloat(e.target.value);
                                  setBasket(updatedBasket);
                                }}
                                className="bg-transparent text-sm"
                              />
                            </div>
                          </div>

                          <div className="flex rounded-2xl px-1 border border-gray-300 bg-white items-center justify-start">
                            <button
                              onClick={() => changeProductQuantity("decrease", item.product["PRODUCT.CODE"], basket, setBasket)}
                              disabled={item.quantity === 1}
                              className="border-gray-300 border-r px-1"
                            >
                              -
                            </button>
                            <input
                              type="number"
                              value={item.quantity || undefined}
                              className="px-1 bg-transparent text-center w-12"
                              onChange={(e) => {
                                const updatedBasket = [...basket];
                                updatedBasket[index].quantity = parseInt(e.target.value);
                                setBasket(updatedBasket);
                              }}
                            />
                            <button
                              onClick={() => changeProductQuantity("increase", item.product["PRODUCT.CODE"], basket, setBasket)}
                              className="border-gray-300 border-l px-1"
                            >
                              +
                            </button>
                          </div>
                          
                        </div>
                      </div>
                      
                    </td>
                    <td className="text-sm text-center">
                      <div className="relative">
                        <div className="flex items-center justify-center gap-2">
                          <p className="text-sm text-center">{item.product["Total Stock"].toLocaleString()} (100)</p>
                          <div className="flex items-center justify-center bg-widget-blue text-xs rounded-full px-2 py-1 group">
                            <i className="fas fa-info"></i>
                          </div>
                        </div>
                        <div className="absolute top-6 right-0 bg-white rounded-md shadow-md opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50 w-auto pb-2 border border-gray-300"> 
                          <h3 className="font-semibold text-sm text-center py-2">Product Stock Levels</h3>
                          <div className="table w-full text-left">
                            <thead>
                              <tr>
                                <th className="border-b border-gray-400 text-center text-sm pl-4">Branch</th>
                                <th className="border-b border-gray-400 text-center text-sm px-1 shrink-0 no-wrap">Stock</th>
                                <th className="border-b border-gray-400 text-center text-sm px-1 pr-4">Incoming</th>
                              </tr>
                            </thead>
                            <tbody>
                              {["Bournemouth", "Hull", "Sheffield"].map((branch: string, index: number) => (
                                <tr key={index}>
                                  <td><p className="text-sm text-center px-1 pl-4">{branch}</p></td>
                                  <td><p className="text-sm text-center px-1">{(item.product as any)[`${branch.toUpperCase()}`].toLocaleString()}</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">33</p></td>
                                </tr>
                              ))}
                            </tbody>
                          </div>
                        </div> 
                      </div>
                    </td>
                    <td className="text-sm text-center">£{item.product["COST.PRICE"].toFixed(2)}</td>
                    <td className="text-sm text-center">{
                      item.product["Terms Discount"] 
                        ? <div className="bg-widget-blue text-widget-blue font-bold px-2 py-1 rounded-full w-20 mx-auto">{(item.product["Terms Discount"] * 100).toFixed(2) + "%"}</div>
                        : "None"
                    }</td>
                    <td className="text-sm text-center">£{((item.basketPrice + (item.basketPrice * (item.tax || 0) / 100)) * (item.quantity || 0)).toFixed(2)}</td>
                    <td>
                      <div className="w-full flex items-center justify-center pr-2 gap-2">
                        <div className="relative">
                          {/* add a button that when hovered over shows the product details */}
                          <button 
                            className="btn btn-secondary"
                            onClick={() => {
                              setActiveBasketItem(item.product["PRODUCT.CODE"]);
                            }}
                          >
                            <i className="fas fa-clock-rotate-left"></i>
                          </button>
                          <div className="absolute top-12 right-0 bg-white rounded-md shadow-md opacity-0 transition-opacity duration-300 pointer-events-none group-hover:opacity-100 z-50 w-auto pb-2 border border-gray-300"> 
                            <h3 className="font-semibold text-sm text-center py-2">Purchase History</h3>
                            <div className="table w-full text-left">
                              <thead>
                                <tr>
                                  <th className="border-b border-gray-400 text-center text-sm pl-4">Date</th>
                                  <th className="border-b border-gray-400 text-center text-sm px-1">Price</th>
                                  <th className="border-b border-gray-400 text-center text-sm px-1">Quantity</th>
                                  <th className="border-b border-gray-400 text-center text-sm pr-4">Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">05/09/24</p></td>
                                  <td><p className="text-sm text-center px-1">£13.43</p></td>
                                  <td><p className="text-sm text-center px-1">12</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£161</p></td>
                                </tr>
                                <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">01/09/24</p></td>
                                  <td><p className="text-sm text-center px-1">£13.35</p></td>
                                  <td><p className="text-sm text-center px-1">100</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£1335</p></td>
                                </tr>
                                <tr>
                                  <td><p className="text-sm text-center px-1 pl-4">28/08/24</p></td>
                                  <td><p className="text-sm text-center px-1">£14</p></td>
                                  <td><p className="text-sm text-center px-1">1</p></td>
                                  <td><p className="text-sm text-center px-1 pr-4">£14</p></td>
                                </tr>
                              </tbody>
                            </div>
                          </div> 
                        </div>

                        <button 
                          className="btn btn-negative"
                          onClick={(e) => {
                            e.stopPropagation();
                            removeItemFromBasket(item.product["PRODUCT.CODE"], basket, setBasket, setActiveBasketItem, activeBasketItem);
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div className="p-4">
            <button 
              className="btn btn-primary w-full flex items-center justify-center uppercase font-extrabold hover:bg-black"
              onClick={() => setProductsModalOpen(true)}
            >
              <i className="fas fa-plus mr-2"></i>
              Add Product
            </button>
          </div>

          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="dashboard-card">
            <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
              <i className="fas fa-calculator"></i>
              <h3 className="font-semibold uppercase text-sm">Order Summary</h3>
            </div>
            
            <div className="p-4">
              <div className="flex justify-between items-center gap-2">
                <p>Subtotal:</p>
                <p>£{basketStats.totalQuoted.toFixed(2)}</p>
              </div>
              <div className="flex justify-between items-center gap-2 mt-2">
                <p>Terms Savings:</p>
                <p>£{basketStats.discount.toFixed(2)}</p>
              </div>
              <div className="flex justify-between items-center gap-2 mt-8">
                <p>Carriage/Shipping Fee:</p>
                <div className="flex rounded border border-gray-300 bg-white items-center justify-start">
                  <div className="border-gray-300 border-r px-1">
                    <p className="text-sm">£</p>
                  </div>
                  <div className="px-1">
                    <input 
                      type="number" 
                      className="input text-lg text-light w-24 text-right pr-2 outline-none" 
                      value={deliveryFee || undefined}
                      onChange={(e) => setDeliveryFee(parseFloat(e.target.value))}
                    />
                  </div>
                </div>
                
              </div>
              <div className="flex justify-between items-center gap-2 mt-2">
                <p>Expected Delivery Date:</p>
                <input 
                  type="date" 
                  className="input text-lg text-light border border-gray-300 text-right pr-2 rounded-md" 
                  value={expectedDeliveryDate}
                  onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                />
              </div>
                {expectedDeliveryDate && new Date(expectedDeliveryDate) < new Date() && 
                <p className="font-bold text-red-400 text-lg">Your delivery date is in the past!</p>
                }

              <div className="flex justify-between items-center gap-2 mt-8">
                <h3 className="font-semibold text-lg">Order Total:</h3>
                <p>£{(basketStats.totalQuoted + (deliveryFee || 0)).toFixed(2)}</p>
              </div>
            </div>
          </div>

          <div className="dashboard-card">
            <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
              <i className="fas fa-sticky-note"></i>
              <h3 className="font-semibold uppercase text-sm">Order Notes</h3>
            </div>
            
            <div className="p-4">
              <button 
                className="btn btn-primary w-full flex items-center justify-center uppercase font-extrabold hover:bg-black"
                onClick={() => setProductsModalOpen(true)}
              >
                <i className="fas fa-plus mr-2"></i>
                Add Note
              </button>
            </div>
          </div>

          <div className="dashboard-card">
            <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
              <i className="fas fa-history"></i>
              <h3 className="font-semibold uppercase text-sm">Activity History</h3>
            </div>
            
            <div className="p-4">
              <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created draft Purchase Order.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPurchase;