import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useScreen } from '../context';

import { fetchSupplier, createSupplier } from '../api';

import NewNoteModal from './widgets/newNoteModal';
import BackButton from '../components/backLink';

import { Supplier } from '../types';

const SuppliersCard = () => {
  const { id: supplierId } = useParams<{ id: string }>();
  const newSupplier = supplierId === "new";

  const tabOptions = ["Overview"];
  const [tab, setTab] = useState(tabOptions[0]);
  const [fetchingData, setFetchingData] = useState(true);

  const { setScreenId, setPageHasDirtyForm } = useScreen();
  setScreenId(`PO- ${supplierId}`)

  const [supplierData, setSupplierData] = useState({} as Supplier);
  const [noteModalOpen, setNoteModalOpen] = useState(false);

  useEffect(() => {
    if (newSupplier || !supplierId) {
      setFetchingData(false);
      return
    }
    
    const fetchData = async () => {
      try {
        const data = await fetchSupplier(supplierId);
        setSupplierData(data[0]);
        console.log(data);
      } catch (error) {
        console.error('There was a problem fetching accounts:', error);
      }
      setFetchingData(false);
    };

    fetchData();
  }, [supplierId]);

  const inputVariable = (label: string, valueKey: keyof Supplier) => {
    return <>
      <div className="label">{label}</div>
      <input 
        type="text" 
        className="input" 
        placeholder={`${label}...`} 
        value={supplierData[valueKey]} 
        onChange={(e) => {
          setPageHasDirtyForm(true);
          setSupplierData({ ...supplierData, [valueKey]: e.target.value })
        }}
      />
    </>
  }

  const attemptSave = async () => {
    try {
      let createResponse = await createSupplier(supplierData);
      console.log(createResponse);
      //window.location.href = "/maintenance";
    } catch (error) {
      console.error('There was a problem saving the supplier:', error);
    }
  }

  const attemptUpdate = async () => {
    try {
      //let updateResponse = await updateSupplier(supplierId, supplierData);
      //console.log(updateResponse);
    } catch (error) {
      console.error('There was a problem updating the supplier:', error);
    }
  }

  if (fetchingData) {
    return (
      <div className="dashboard-card w-full h-full p-4 flex flex-col justify-center items-center">
        <div className="flex flex-col justify-center items-center flex-grow">
          <i className="fa-sharp fa-circle-notch fa-spin text-4xl"></i>
          <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
        </div>
      </div>
    );
  }

  return (
  <div className="flex flex-col gap-6 items-start">
    {noteModalOpen &&
      <NewNoteModal
        note={""}
        setNote={(note) => console.log(note)}
        modalOpen={noteModalOpen}
        setModalOpen={setNoteModalOpen}
      />
    }

    <div className="flex items-center gap-4 w-full">
      <BackButton />

      {newSupplier 
        ? <>
            <button
              className="btn btn-secondary ml-auto"
              onClick={() => {
                window.location.href = "/maintenance";
              }}
            >
              Discard
            </button>

            <button
              className="btn btn-primary"
              onClick={attemptSave}
            >
              Save
            </button>
          </>
        : <button
            className="btn btn-primary ml-auto"
            onClick={ attemptUpdate}
          >
            Save
          </button>
      }
    </div>

    <div className="w-full dashboard-card">
        <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
          <i className="fas fa-user"></i>
          <h3 className="font-semibold uppercase text-sm">Supplier Details</h3>
        </div>

        <div className="p-4">
          <h2 className="font-semibold text-2xl">
            {newSupplier 
              ? "New Supplier"
              : supplierData.NAME
            }
          </h2>
        </div>
      </div>

    <div className="grid md:grid-cols-3 gap-6 w-full">
      <div className="col-span-2">
        <div className="dashboard-card bg-base-100">
          <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
            {tabOptions.map((option, index) => (
              <button 
                key={index} 
                className={`px-2 pb-2 btn font-bold
                    ${tab === option 
                      ? 'btn-primary' 
                      : 'hover:btn-primary'}
                `} 
                onClick={() => setTab(option)}
              >
                {option}
              </button>
            ))}
          </div>

          {fetchingData 
            ? <div className="flex flex-col justify-center items-center flex-grow py-12">
                <i className="fa-solid fa-spinner fa-spin text-4xl"></i>
              </div>
            : <div className="p-4">
                {tab === "Overview" && <>
                  <h3 className="font-semibold">Primary Details</h3>

                  <div className="form-container mt-4">
                    <div className="form-container mt-4">
                      {inputVariable("Supplier Name", "NAME")}
                      {inputVariable("Phone", "PHONE")}
                      {inputVariable("Contact", "CONTACT")}
                    </div>
                  </div>
                </>}
              </div>
          }
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="dashboard-card">
          <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
            <i className="fas fa-sticky-note"></i>
            <h3 className="font-semibold uppercase text-sm">Customer Notes</h3>
          </div>
          
          <div className="p-4">
            <button 
              className="btn btn-primary w-full flex items-center justify-center uppercase font-extrabold hover:bg-black"
              onClick={() => setNoteModalOpen(true)}
            >
              <i className="fas fa-plus mr-2"></i>
              Add Note
            </button>
          </div>
        </div>

        <div className="dashboard-card">
          <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
            <i className="fas fa-history"></i>
            <h3 className="font-semibold uppercase text-sm">Activity History</h3>
          </div>
          
          <div className="p-4">
            <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created supplier record.</p>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default SuppliersCard;