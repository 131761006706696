import { Customer } from './types';

const BASE_URL = process.env.REACT_APP_API_URL;

const fetchFromApi = async (apiPath: string, bodyData: {}) => {
  const response = await fetch(`${BASE_URL}${apiPath}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  if (!response.ok) throw new Error('Network response was not ok');
  let data = await response.json();
  console.log(data);

  return data;
}

export const fetchProducts = async () => {
  try {
    const response = await fetchFromApi('products/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchProduct = async (productCode: string) => {
  try {
    const response = await fetchFromApi('products/fetch', { productCode });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching product:', error);
  }
}

export const updateProduct = async (productCode: string, OriginalValue: any, Value: any) => {
  try {
    const response = await fetchFromApi('products/update', { productCode, OriginalValue, Value });
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem updating product:', error);
  }
}

export const fetchBulkProducts = async (productCodes: string[]) => {
  try {
    const response = await fetchFromApi('products/bulk-fetch', { productCodes });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchProductsToClear = async () => {
  try {
    const response = await fetchFromApi('products/fetch-to-clear', {});
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchMetaDataForTopProducts = async (productCodes: string[]) => {
  try {
    const response = await fetchFromApi('products/top-products-data', { productCodes });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching top product data:', error);
  }
}

export const searchProducts = async (searchQuery: string) => {
  try {
    const response = await fetchFromApi('products/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching products:', error);
  }
}

export const listSuppliers = async () => {
  try {
    const response = await fetchFromApi('suppliers/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching suppliers:', error);
  }
}

export const createSupplier = async (supplier: any) => {
  try {
    const response = await fetchFromApi('suppliers/create', { supplier });
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem creating supplier:', error);
  }
}

export const fetchSupplier = async (supplierId: string) => {
  try {
    const response = await fetchFromApi('suppliers/fetch', { supplierId });
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching supplier:', error);
  }
}

export const searchSuppliers = async (searchQuery: string) => {
  try {
    const response = await fetchFromApi('suppliers/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching suppliers:', error);
  }
}

export const listCustomers = async () => {
  try {
    const response = await fetchFromApi('customers/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching customers:', error);
  }
}

export const fetchCustomer = async (customerId: string) => {
  try {
    const response = await fetchFromApi('customers/fetch', { customerId });
    console.log(response);
    return response.ExecuteCommand.Output[0] || null;
  }
  catch (error) {
    console.error('There was a problem fetching customers:', error);
  }
}

export const updateCustomer = async (customerId: string, OriginalValue: Customer, Value: Customer) => {
  try {
    const response = await fetchFromApi('customers/update', { customerId, OriginalValue, Value });
    console.log(response);
    
    // Check for the presence of "Error" in the response.ExecuteCommand.Success string
    if (response.ExecuteCommand.Success.includes('Error')) {
      if (response.ExecuteCommand.Success.includes('Please Refresh')) {
        throw new Error('New changes have been made to this page. Please refresh and try again, your changes will be lost.');
      } 
      else throw new Error('Error updating customer');
    }

    return response.ExecuteCommand.Output;
  }
  catch (error) {
    throw error;
  }
} 

export const searchCustomers = async (searchQuery: string) => {
  try {
    const response = await fetchFromApi('customers/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching customers:', error);
  }
}

export const fetchDashboardData = async () => {
  try {
    const response = await fetchFromApi('salesOrders/fetch-dashboard', {});
    console.log(response);
    if (!response.orders) throw new Error('Missing data in response');
    return { orders: response.orders.ExecuteCommand.Output };
  }
  catch (error) {
    console.error('There was a problem fetching dashboard data:', error);
  }
}

export const listSalesOrders = async () => {
  try {
    const response = await fetchFromApi('salesOrders/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchSalesOrder = async (orderId: string) => {
  try {
    const response = await fetchFromApi('salesOrders/fetch', { orderId });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching order:', error);
  }
}

export const listPurchaseOrders = async () => {
  try {
    const response = await fetchFromApi('purchaseOrders/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching purchase orders:', error);
  }
}

export const fetchPurchaseOrder = async (orderId: string) => {
  try {
    const response = await fetchFromApi('purchaseOrders/fetch', { orderId });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching order:', error);
  }
}

export const createPurchaseOrder = async (purchaseOrder: any) => {
  try {
    const response = await fetchFromApi('purchaseOrders/create', { purchaseOrder });
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem creating purchase order:', error);
  }
}