import React, { useState, useEffect } from 'react';
import { useScreen } from '../../context';

const NewBroadcastsPage = () => {
  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("NEW-BROADCAST");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  const [announcementTitle, setAnnouncementTitle] = useState("");
  const [announcementMessage, setAnnouncementMessage] = useState("");
  const [announcementScope, setAnnouncementScope] = useState("All Sites");
  const branches = ["Bournemouth Branch", "Hull Branch", "Sheffield Branch"];

  return (
    <div className="flex flex-col gap-6 items-start w-full">
      <div className="flex w-full items-end gap-4 dashboard-card p-4">

        <h2 className="font-semibold text-2xl">New Announcement</h2>

        <button
          className="btn btn-secondary ml-auto"
          onClick={() => {
            window.location.href = "/config";
          }}
        >
          Discard
        </button>

        <button
          className="btn btn-primary disabled:opacity-50"
          disabled={announcementTitle === "" || announcementMessage === ""}
        >
          Broadcast
        </button>
      </div>

      <div className="grid md:grid-cols-2 gap-6 w-full">
        <div className="dashboard-card">
          <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
            <i className="fas fa-bullhorn"></i>
            <h3 className="font-semibold uppercase text-sm">Announcement Title</h3>
          </div>

          <div className="p-4">
            <input
              type="text"
              className="input text-xl text-light outline-none w-full font-bold"
              placeholder="Enter announcement title..."
              value={announcementTitle}
              onChange={(e) => setAnnouncementTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="dashboard-card">
          <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
            <i className="fas fa-users"></i>
            <h3 className="font-semibold uppercase text-sm">Audience</h3>
          </div>

          <div className="p-4">
            <select 
              className="input text-xl text-light outline-none"
              value={announcementScope}
              onChange={(e) => setAnnouncementScope(e.target.value)}
            >
              <option value="All Sites">All Sites</option>
              {branches.map((branch: string, index: number) => (
                <option key={index} value={branch}>{branch}</option>
              ))}
            </select>
          </div>

        </div>
      </div>

      <div className="dashboard-card w-full">
        <div className="bg-widget-blue text-widget-blue p-4 flex items-center gap-2">
          <i className="fas fa-comment-alt"></i>
          <h3 className="font-semibold uppercase text-sm">Announcement Message</h3>
        </div>

        <div className="p-4">
          <textarea
            className="input text-lg text-light outline-none w-full"
            placeholder="Enter announcement message..."
            rows={5}
            value={announcementMessage}
            onChange={(e) => setAnnouncementMessage(e.target.value)}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default NewBroadcastsPage;