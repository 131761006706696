import React, { useEffect, useState } from 'react';
import { useScreen } from '../context';
import { Link } from 'react-router-dom';

import LightMetricCard from '../components/widgets/lightMetricCard';
import PageTabs from '../components/pageTabs';
import SearchBar from '../components/searchBar';
import Pagination from '../components/pagination';

import { fetchProductData, sortAlphabeticallyWithNonAlphaAtEnd } from "../utils";
import { Product } from '../types';

import { searchProducts } from '../api';
import { Resizable } from 're-resizable';

const ProductPage = () => {
  const tabOptions = ["All Products", "Low Stock"];
  const [tab, setTab] = useState(tabOptions[0] as string);
  const [fetching, setFetching] = useState(true);
  const [productData, setProductData] = useState<Product[]>([]);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [searchedData, setSearchedData] = useState([] as any[]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const [filterCategories, setFilterCategories] = useState([
    { title: "Solar Panels", enabled: false, category: "Solar Panels" },
    { title: "Power Inverters", enabled: false, category: "Power Inverters" },
    { title: "Battery Storage", enabled: false, category: "Batteries" },
    { title: "EV Chargers", enabled: false, category: "Vehicle Battery Chargers" },
  ]);

  const [filterBrands, setFilterBrands] = useState([
    { title: "Duracell Energy", enabled: false, brand: "Duracell Energy" },
    { title: "EcoFlow", enabled: false, brand: "EcoFlow" },
    { title: "Enphase", enabled: false, brand: "Enphase" },
    { title: "Fox ESS", enabled: false, brand: "Fox ESS" },
    { title: "GivEnergy", enabled: false, brand: "GivEnergy" },
    { title: "Growatt", enabled: false, brand: "Growatt" },
    { title: "myenergi", enabled: false, brand: "myenergi" },
    { title: "SolarEdge", enabled: false, brand: "SolarEdge" },
    { title: "SolaX", enabled: false, brand: "SolaX" },
    { title: "Solis", enabled: false, brand: "Solis" },
    { title: "Sunsynk", enabled: false, brand: "Sunsynk" },
  ]);

  const [sortConfig, setSortConfig] = useState({ key: 'DESCRIPTION', direction: 'asc' });
  const handleSort = (key: string) => {
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      setSortConfig({ key, direction: 'desc' });
    } else {
      setSortConfig({ key, direction: 'asc' });
    }
  };

  useEffect(() => {
    setFilteredAndSearchedProducts(searchedData
      .filter((product: Product) => {
        const categoryFilterApplied = filterCategories.some(category => category.enabled);
        const brandFilterApplied = filterBrands.some(brand => brand.enabled);

        const categoryMatch = categoryFilterApplied
          ? filterCategories.some(category => category.enabled && category.category === product['PROD.GROUP'])
          : true;

        const brandMatch = brandFilterApplied
          ? filterBrands.some(brand => brand.enabled && brand.brand === product['SUPPLIER'])
          : true;

        return categoryMatch && brandMatch
      })
    );
  }, [filterCategories, filterBrands, searchedData]);

  const sortedData = () => {
    if (sortConfig.key === "DESCRIPTION") {
      return sortConfig.direction === 'desc'
        ? sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, 'DESCRIPTION')
        : sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedProducts, 'DESCRIPTION').reverse();
    }
    else {
      return [...filteredAndSearchedProducts].sort((a, b) => {
        if (sortConfig.direction === 'asc') {
          if (typeof a[sortConfig.key] === 'number') {
            return a[sortConfig.key] - b[sortConfig.key];
          }
          return a[sortConfig.key].localeCompare(b[sortConfig.key]);
        } else {
          if (typeof a[sortConfig.key] === 'number') {
            return b[sortConfig.key] - a[sortConfig.key];
          }
          return b[sortConfig.key].localeCompare(a[sortConfig.key]);
        }
      });
    }
  }

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("PRODUCTS");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products } = await fetchProductData();
        setSearchedData(products);
        setProductData(products);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="grid md:grid-cols-3 gap-4">
        <LightMetricCard title="Total Products" count={2500} icon="warehouse-full" chartColor="green" />
        <LightMetricCard title="Unpublished" count={0} icon="clock" chartColor="yellow" />
        <LightMetricCard 
          title="Low Stock" 
          count={productData.filter(product => product["Total Stock"] < 5).length}
          icon="circle-exclamation" 
          chartColor="red" 
        />
      </div>
      
      {fetching && 
        <div className="flex justify-center items-center h-full">
          <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
        </div>
      }

      {!fetching && <>
        <div className="flex justify-between items-center flex-wrap gap-4">
          <PageTabs tabs={tabOptions} activeTab={tab} setActiveTab={setTab} card={true} />
          <SearchBar initialData={productData} setResults={setSearchedData} searchFunction={searchProducts} childQuery=''/>
        </div>
        <div className="flex flex-col md:flex-row gap-4">
          <div className="flex flex-col gap-4 dashboard-card rounded-lg overflow-hidden flex-grow">
            <table className="table-auto max-w-full">
              <thead className="sticky top-0 bg-widget-blue text-widget-blue">
                <tr>
                    {[
                    { name: "Product", key: "DESCRIPTION" },
                    { name: "Supplier", key: "SUPPLIER" },
                    { name: "List Price", key: "LIST.PRICE" },
                    { name: "Cost Price", key: "COST.PRICE" },
                    { name: "Stock", key: "Total Stock" }
                    ].map((header, index) => (
                    <th
                      key={index}
                      className={`text-left px-1 py-4 text-sm cursor-pointer ${index !== 4 ? 'border-r border-primary' : 'text-center'}`}
                      onClick={() => handleSort(header.key)}
                    >
                      <Resizable
                      enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                      minWidth={125}
                      className="flex items-center gap-2 shrink-0 w-full"
                      >
                      <p className="shrink-0">{header.name}</p>
                      {sortConfig.key === header.key && (
                        <p><i className={`fa-solid fa-caret-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i></p>
                      )}
                      </Resizable>
                    </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {sortedData()
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map((product: Product, rowIndex: number) => (
                    <tr key={rowIndex}>
                      <td>
                        <Link className="flex gap-4 items-center pr-2 hover:underline pl-4" to={`/product/${product["PRODUCT.CODE"]}`}>
                          <img src={product["PICT.FLAG"]} alt={product.DESCRIPTION} className="w-14 h-14 rounded-md" />
                          <div>
                            <p className="font-semibold text-sm">{product.DESCRIPTION.length > 45 ? product.DESCRIPTION.slice(0, 45) + "..." : product.DESCRIPTION}</p>
                            <p className="text-xs">{product["PROD.GROUP"]}</p>
                          </div>
                        </Link>
                      </td>
                      <td><p className="text-sm px-1">{product["SUPPLIER"]}</p></td>
                      <td><p className="text-sm px-1">£{product["LIST.PRICE"]}</p></td>
                      <td><p className="text-sm px-1">£{product["COST.PRICE"]}</p></td>
                      <td><p className="text-sm px-1 pr-4">{product["Total Stock"]}</p></td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            
            <div className="px-4 pb-4 mt-auto">
              <Pagination 
                numberOfEntries={sortedData().length} 
                currentPage={currentPage} 
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
          </div>

          <div>
            <div className="dashboard-card rounded-lg overflow-hidden sticky top-10">
              <div className="flex items-center bg-widget-blue text-widget-blue gap-2 p-4">
                <i className="fa-solid fa-filter"></i>
                <p className="font-semibold">Filters</p>
              </div>

              <div className="p-4">
                <div className="pb-2">
                  <h3 className="text-lg font-semibold pl-1">Categories</h3>
                </div>
                <div className="flex flex-col gap-1 pl-1">
                  {filterCategories.map((category, index) => (
                    <div key={category.title} className="flex items-center gap-2">
                      <input 
                        type="checkbox"
                        className="checkbox"
                        id={category.title}
                        checked={category.enabled}
                        onChange={(e) => {
                          setFilterCategories(prev => {
                            const newFilters = [...prev];
                            newFilters[index].enabled = e.target.checked;
                            return newFilters;
                          });
                        }}
                      />
                      <label htmlFor={category.title}>{category.title}</label>
                    </div>
                  ))}
                </div>

                <div className="mt-4 pb-2">
                  <h3 className="text-lg font-semibold pl-1">Brands</h3>
                </div>
                <div className="flex flex-col gap-1 pl-1">
                  {filterBrands.map((brand, index) => (
                    <div key={brand.title} className="flex items-center gap-2">
                      <input 
                        type="checkbox"
                        className="checkbox"
                        id={brand.title}
                        checked={brand.enabled}
                        onChange={(e) => {
                          setFilterBrands(prev => {
                            const newFilters = [...prev];
                            newFilters[index].enabled = e.target.checked;
                            return newFilters;
                          });
                        }}
                      />
                      <label htmlFor={brand.title}>{brand.title}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}
    </div>
  );
}

export default ProductPage;