import React, { useEffect, useState } from 'react';
import ChartCard from './chartCard';

import { processDataIntoWeeklyTotals } from '../../pages/dashboard/utils';
import { Order } from '../../types';

import SimpleBarChart from './charts/barChart';
import MetricChangeHandler from './metricChangeHandler';

const WeeklyTotals = ({ ordersThisYear }: { ordersThisYear: Order[] }) => {
  const [rollingWeeklyOrders, setRollingWeeklyOrders] = useState<{ name: string; "Sales": number }[]>([]);
  const [widgetLoading, setWidgetLoading] = useState(true);
  const [metric, setMetric] = useState("Sales");
  const [change, setChange] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (ordersThisYear.length === 0) return;
    const weeklyFigures = processDataIntoWeeklyTotals(ordersThisYear, metric);
    console.log(weeklyFigures);
    setRollingWeeklyOrders(weeklyFigures);
    setChange(weeklyFigures[weeklyFigures.length - 1][metric] - weeklyFigures[weeklyFigures.length - 2][metric]);
    setWidgetLoading(false);
  }, [ordersThisYear, metric]);

  const handleMetricChange = (newMetric: string) => {
    setMetric(newMetric);
    setShowDropdown(false);
  };

  if (widgetLoading) {
    return (
      <div className="dashboard-card bg-base-100 p-4">
        <div className="flex justify-end items-center gap-2 mb-2">
          <i className="cursor-pointer fa-regular fa-pen-to-square" ></i>
        </div>

        <div className="flex flex-col gap-4 items-center justify-center w-full h-48 rounded-md p-4">
          <i className="fas fa-spinner fa-spin text-2xl md:text-4xl"></i>
          <h3 className="font-bold text-center">Loading daily data...</h3>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className="dashboard-card bg-base-100 p-4">
        <div className="flex justify-between items-center gap-2 mb-6">
          <div>
            <h2 className="font-bold md:text-2xl">HDM Weekly {metric}</h2>
            <p className="text-muted text-xs">
              <MetricChangeHandler metric={metric} metricChange={change} giveTextColor={true} />
              &nbsp;{change < 0 ? `behind last week's` : `ahead of last week's`} {metric}.</p>
          </div>

          <div className="relative">
            <i 
              className="cursor-pointer fa-regular fa-ellipsis-vertical text-muted ml-auto hover:text-black" 
              onClick={() => setShowDropdown(!showDropdown)}
            ></i>
            {showDropdown && (
              <div className="absolute right-0 w-32 bg-white border border-gray-200 rounded shadow-md z-50">
                <ul className="">
                  <li 
                    className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Sales' ? 'bg-blue-100' : ''}`}
                    onClick={() => handleMetricChange('Sales')}
                  >
                    Sales
                  </li>
                  <li 
                    className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Orders' ? 'bg-blue-100' : ''}`}
                    onClick={() => handleMetricChange('Orders')}
                  >
                    Orders
                  </li>
                  <li 
                    className={`cursor-pointer px-4 py-2 hover:bg-gray-100 ${metric === 'Profit' ? 'bg-blue-100' : ''}`}
                    onClick={() => handleMetricChange('Profit')}
                  >
                    Profit
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <SimpleBarChart chartData={rollingWeeklyOrders} includeAverageLine={true} metric={metric} />
      </div>
    )
  }
}

export default WeeklyTotals;