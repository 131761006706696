import React, { useState, useEffect } from 'react';
import { useScreen } from '../context';
import { listSuppliers, searchSuppliers, listCustomers, searchCustomers } from '../api';
import { Link } from 'react-router-dom';

import PageTabs from '../components/pageTabs';
import Pagination from '../components/pagination';
import SearchBar from '../components/searchBar';
import LightMetricCard from '../components/widgets/lightMetricCard';

import { sortAlphabeticallyWithNonAlphaAtEnd, formatNumber } from '../utils';

import { Tooltip } from 'react-tooltip'

import { Customer, Supplier } from '../types';
import { Resizable } from 're-resizable';

const MaintenancePage = () => {
  const tabOptions = ["Suppliers", "Customers"];
  const [tab, setTab] = useState(tabOptions[0]);
  const [filteredAndSearchedData, setFilteredAndSearchedData] = useState([] as any[]);
  const [customerData, setCustomerData] = useState([] as Customer[]);
  const [supplierData, setSupplierData] = useState([] as Supplier[]);
  const [fetching, setFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: 'NAME', direction: 'asc' });
  const itemsPerPage = 25;

  const { setScreenId, pageHasDirtyForm, setPageHasDirtyForm } = useScreen();
  setScreenId("MAINTENANCE");
  pageHasDirtyForm && setPageHasDirtyForm(false);

  useEffect(() => {
    const fetchData = async () => {
      if (tab === "Customers") {
        try {
          if (customerData.length === 0) {
            const customers = await listCustomers();
            let validCustomers = customers.filter((customer: any) => customer["NAME"] !== null);
            setCustomerData(validCustomers);
            setFilteredAndSearchedData(validCustomers);
          } else {
            setFilteredAndSearchedData(customerData);
          }
        } catch (error) {
          console.error('There was a problem fetching customers:', error);
        }
      } else {
        try {
          if (supplierData.length === 0) {
            const suppliers = await listSuppliers();
            let validSuppliers = suppliers.filter((supplier: any) => supplier["NAME"] !== null);
            setSupplierData(validSuppliers);
            setFilteredAndSearchedData(validSuppliers);
          } else {
            setFilteredAndSearchedData(supplierData);
          }
        } catch (error) {
          console.error('There was a problem fetching suppliers:', error);
        }
      }
      setFetching(false);
    };

    fetchData();
  }, [tab]);

  const updateTab = (tab: string) => {
    setFetching(true);
    setFilteredAndSearchedData([]);
    setTab(tab);
  };

  const handleSort = (key: string) => {
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      setSortConfig({ key, direction: 'desc' });
    } else {
      setSortConfig({ key, direction: 'asc' });
    }
  };

  const sortedData = () => {
    if (sortConfig.key === "NAME") {
      return sortConfig.direction === 'desc'
        ? sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedData, 'NAME')
        : sortAlphabeticallyWithNonAlphaAtEnd(filteredAndSearchedData, 'NAME').reverse();
    }
    else {
      return [...filteredAndSearchedData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
  }

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="grid md:grid-cols-4 gap-4">
        <LightMetricCard title="Customers" count={1345} icon="user-tag" chartColor="blue" />
        <LightMetricCard title="Active Customers" count={14} icon="user-tie-hair" chartColor="red" />
        <LightMetricCard title="Suppliers" count={3820} icon="user-pen" chartColor="yellow" />
        <LightMetricCard title="Active Suppliers" count={4690} icon="user-crown" chartColor="green" />
      </div>

      <div className="flex justify-between items-center flex-wrap gap-4">
        <Link
          to={tab === "Customers" ? "/customer/new" : "/supplier/new"}
          className="btn btn-primary"
        >
          <i className="fa-solid fa-plus"></i>&nbsp;{tab === "Customers" ? "Customer" : "Supplier"}
        </Link>
        <PageTabs tabs={tabOptions} activeTab={tab} setActiveTab={updateTab} card={true} />
        <SearchBar
          childQuery=''
          initialData={tab === "Customers" ? customerData : supplierData}
          setResults={setFilteredAndSearchedData}
          searchFunction={tab === "Customers" ? searchCustomers : searchSuppliers}
        />
      </div>
      <div className="flex flex-col gap-4 dashboard-card rounded-lg overflow-hidden">
        {fetching ? (
          <div className="flex justify-center items-center h-full">
            <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
          </div>
        ) : (
          <>
            <div>
              <table className="table-auto w-full">
                <thead className="sticky top-0 bg-widget-blue text-widget-blue">
                  <tr>
                    {["NAME", "ACCNO", "CONTACT", "CONTACT DETAILS", "YTD Spend"].map((header, index) => {
                      if (header === "YTD Spend") {
                        return (
                          <th
                            key={index}
                            className={`text-left px-1 py-2 text-sm cursor-pointer ${index !== 4 ? 'border-r border-primary' : 'text-center'}`}
                            onClick={() => handleSort(header)}
                          >
                            <Resizable 
                              key={index} 
                              enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                              minWidth={125} 
                              className="flex items-center gap-2 shrink-0 w-full"
                            >
                              <Tooltip id="my-tooltip" place='top-end' />
                                <p 
                                  className="shrink-0" 
                                  data-tooltip-id="my-tooltip" 
                                  data-tooltip-content={`The total amount  ${tab === "Customers" ? "this customer has spent with you" : "you've spent with this supplier"}  this year.`}
                                >
                                  {header}
                                </p>
                                {sortConfig.key === header && (
                                  <p><i className={`fa-solid fa-caret-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i></p>
                                )}
                            </Resizable>
                          </th>
                        )
                      }
                      else return (
                        <th
                          className={`text-left px-1 py-2 text-sm cursor-pointer ${index !== 4 ? 'border-r border-primary' : 'text-center'}`}
                          onClick={() => handleSort(header)}
                        >
                            <Resizable 
                              key={index} 
                              enable={{ right: true, left: true, top: false, bottom: false, topLeft: false, topRight: false, bottomLeft: false, bottomRight: false }}
                              minWidth={125} 
                              className="flex items-center gap-2 shrink-0 w-full"
                            >
                              <p className="shrink-0">{header}</p>
                              {sortConfig.key === header && (
                                <p><i className={`fa-solid fa-caret-${sortConfig.direction === 'asc' ? 'up' : 'down'}`}></i></p>
                              )}
                          </Resizable>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {sortedData()
                    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                    .map((dataEntry: any, rowIndex: number) => {
                      const itemLink =
                        tab === "Customers"
                          ? `/customer/${dataEntry["ACCNO"]}`
                          : `/supplier/${dataEntry["ACCNO"]}`;
                      if (tab === "Suppliers") {
                        if (dataEntry["PURCH.EMAIL"].includes(",")) {
                          dataEntry["PURCH.EMAIL"] = dataEntry["PURCH.EMAIL"].split(",")[0];
                        }
                      }
                      return (
                        <tr key={rowIndex}>
                          <td className="border-r border-grey-300">
                            <Link className="flex gap-4 items-center pl-3 pr-2 hover:underline" to={itemLink}>
                              <p className="font-semibold text-sm">{dataEntry.NAME}</p>
                            </Link>
                          </td>
                          <td className="border-r border-grey-300">
                            <Link className="flex gap-4 items-center pl-3 hover:underline" to={itemLink}>
                              <p className="font-semibold text-sm">{dataEntry["ACCNO"]}</p>
                            </Link>
                          </td>
                          <td className="border-r border-grey-300">
                            <p className="font-semibold text-sm pl-3">{dataEntry.CONTACT}</p>
                          </td>
                          <td className="border-r border-grey-300">
                            <p className="font-semibold text-sm pl-3">
                              {tab === "Customers" ? dataEntry["EMAIL"] : dataEntry["PURCH.EMAIL"]}
                            </p>
                            <p className="font-semibold text-xs pl-3">{dataEntry["PHONE"]}</p>
                          </td>
                          <td>
                            <p className="font-semibold pl-5 text-sm">
                              £{formatNumber(tab === "Customers" ? dataEntry["YTD"] : dataEntry["SUPP.YTD"])}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <div className="px-4 pb-4">
              <Pagination
                numberOfEntries={filteredAndSearchedData.length}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MaintenancePage;