import React, { useState } from 'react';
import ModalWithChildren from '../modalWithChildren';

type NewNoteModalProps = {
  note: string;
  setNote: (note: string) => void;
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const NewNoteModal: React.FC<NewNoteModalProps> = ({ note, setNote, modalOpen, setModalOpen }) => {
  const [newNote, setNewNote] = useState(note);

  const handleSave = () => {
    setNote(newNote);
  };

  return (
    <ModalWithChildren
      children={
        <div className="flex flex-col items-center gap-4">
          <h2 className="font-semibold text-2xl">New Note</h2>
          <textarea
            value={newNote}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            onChange={(e) => setNewNote(e.target.value)}
            placeholder="Enter your note here..."
          />
          <button
            className="btn btn-green w-full mt-4"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="btn btn-secondary w-full"
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      }
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      />
  );
};

export default NewNoteModal;