import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useScreen } from '../context';

import { fetchCustomer, updateCustomer } from '../api';
import { Customer } from '../types';

import BackButton from '../components/backLink';

import ModalWithChildren from './modalWithChildren';
import NewNoteModal from './widgets/newNoteModal';

const CustomersCard = () => {
  const { id: customerId } = useParams<{ id: string }>();
  const newCustomer = customerId === "new";

  const tabOptions = ["Overview", "Financials"];
  const [tab, setTab] = useState(tabOptions[0]);
  const [originalData, setOriginalData] = useState({} as Customer);
  const [customerData, setCustomerData] = useState({} as Customer);
  const [fetchingData, setFetchingData] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [customerStopModalOpen, setCustomerStopModalOpen] = useState(false);

  const [noteModalOpen, setNoteModalOpen] = useState(false);

  const { setScreenId, setPageHasDirtyForm } = useScreen();
  setScreenId(`CUSTOMER- ${customerId}`);

  useEffect(() => {
    if (!customerId) return;

    const fetchData = async () => {
      try {
        const data = await fetchCustomer(customerId);
        console.log(data);

        if (data) {
          setOriginalData(data);
          setCustomerData(data);
        }
      } catch (err) {
        console.error('There was a problem fetching accounts:', err);
        setErrorMessage(String(err));
      }
      setFetchingData(false);
    };

    fetchData();
  }, [customerId]);

  const primaryDetailsFields = [
    { label: "Customer name", key: "Name" },
    { label: "Address", key: "Address" },
    { label: "Postcode", key: "Postcode" },
    { label: "Phone", key: "Phone" },
    { label: "Account Holding Location", key: "AREA" },
    { label: "Customer Sales Rep", key: "REP" },
    { label: "Review Date", key: "REVIEW.DATE" },
    { label: "Trading Terms", key: "TRADING.TERMS" }
  ]

  const updateValue = (key: keyof Customer, value: string) => {
    setPageHasDirtyForm(true);
    setCustomerData({ ...customerData, [key]: value });
  }

  const updateCustomerData = async () => {
    if (!customerId) return;

    try {
      const response = await updateCustomer(customerId, originalData, customerData);
      setPageHasDirtyForm(false);
      console.log(response);
    } catch (err) {
      console.log(err)
      setErrorMessage(String(err));
      console.error('There was a problem updating customer:', err);
    }
  }

  const attemptSave = async () => {
    console.log("attempting to save");
  }

  return (
    <div className="flex flex-col gap-6 items-start">
      {customerStopModalOpen &&
        <ModalWithChildren
          children={
            <div className="flex flex-col gap-2 items-center">
              <h2 className="font-semibold text-2xl">Customer on Stop</h2>
              <p className="mt-4 max-w-[500px] text-center">Putting this Customer on Stop will mean they can no longer purchase any products at all locations, including the Website.<br /><br />Confirm this is the action you want.</p>
              <button
                className="btn btn-green w-full mt-4"
                onClick={() => {
                  updateValue("CREDIT.STATUS", "STOP");
                  setCustomerStopModalOpen(false);
                }}
              >
                Confirm
              </button>
              <button
                className="btn btn-secondary w-full"
                onClick={() => setCustomerStopModalOpen(false)}
              >
                Cancel
              </button>

            </div>}
          modalOpen={customerStopModalOpen}
          setModalOpen={setCustomerStopModalOpen}
        />
      }

      {noteModalOpen &&
        <NewNoteModal
          note={""}
          setNote={(note) => console.log(note)}
          modalOpen={noteModalOpen}
          setModalOpen={setNoteModalOpen}
        />
      }

      <div className="flex items-center gap-4 w-full">
        <BackButton />
        {newCustomer 
          ? <>
              <button
                className="btn btn-secondary ml-auto"
                onClick={() => {
                  window.location.href = "/maintenance";
                }}
              >
                Discard
              </button>

              <button
                className="btn btn-green"
                onClick={attemptSave}
              >
                Save
              </button>
            </>
          : <button
              className="btn btn-green ml-auto"
              onClick={updateCustomerData}
            >
              Save
            </button>
        }
      </div>

      <div className="w-full dashboard-card">
        <div className="bg-widget-green text-widget-green p-4 flex items-center gap-2">
          <i className="fas fa-user"></i>
          <h3 className="font-semibold uppercase text-sm">
            Customer Details{!newCustomer && ` - #${customerId}`}
          </h3>
        </div>

        <div className="p-4">
          <h2 className="font-semibold text-2xl">
            {newCustomer 
              ? "New Customer"
              : customerData.NAME
            }
          </h2>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-6 w-full">
        <div className="col-span-2">
          <div className="dashboard-card bg-base-100">
            <div className="bg-widget-green text-widget-green p-4 flex items-center gap-2">
              {tabOptions.map((option, index) => (
                <button 
                  key={index} 
                  className={`px-2 pb-2 btn font-bold
                      ${tab === option 
                        ? 'btn-green' 
                        : 'hover:btn-primary'}
                  `} 
                  onClick={() => setTab(option)}
                >
                  {option}
                </button>
              ))}
            </div>

            {fetchingData 
              ? <div className="flex flex-col justify-center items-center flex-grow py-12">
                  <i className="fa-solid fa-spinner fa-spin text-4xl"></i>
                </div>
              : <div className="p-4">
                  {tab === "Overview" && <>
                    <h3 className="font-semibold">Primary Details</h3>

                    <div className="form-container mt-4">
                      {primaryDetailsFields.map((field, index) => (
                        <>
                          <div className="label">{field.label}</div>
                          <input 
                            type="text" 
                            className="input" 
                            placeholder={`${field.label}...`} 
                            value={customerData[field.key as keyof Customer]}
                            onChange={(e) => updateValue(field.key as keyof Customer, e.target.value)}
                          />
                        </>
                      ))}
                    </div>
                  </>}

                  {tab === "Financials" && <>
                    <h3 className="font-semibold">Financial Details</h3>

                    <div className="form-container mt-4">
                      <div className="label">Credit Limit</div>
                      <input 
                        type="text" 
                        className="input" 
                        placeholder="Credit Limit..." 
                        value={customerData['CREDIT.LIMIT']}
                        onChange={(e) => updateValue("CREDIT.LIMIT", e.target.value)}
                      />

                      <div className="label">Customer on Stop</div>
                      <input
                        type="checkbox"
                        className="checkbox checkbox-green"
                        checked={customerData['CREDIT.STATUS'] === "STOP"}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCustomerStopModalOpen(true);
                          } else {
                            updateValue("CREDIT.STATUS", "OK");
                          }
                        }}
                      />
                    </div>
                  </>}

                  {errorMessage && <div className="text-red-500 mb-4 mt-12">{errorMessage}</div>}
                </div>
            }
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="dashboard-card">
            <div className="bg-widget-green text-widget-green p-4 flex items-center gap-2">
              <i className="fas fa-sticky-note"></i>
              <h3 className="font-semibold uppercase text-sm">Customer Notes</h3>
            </div>
            
            <div className="p-4">
              <button 
                className="btn btn-green w-full flex items-center justify-center uppercase font-extrabold hover:bg-black"
                onClick={() => setNoteModalOpen(true)}
              >
                <i className="fas fa-plus mr-2"></i>
                Add Note
              </button>
            </div>
          </div>

          <div className="dashboard-card">
            <div className="bg-widget-green text-widget-green p-4 flex items-center gap-2">
              <i className="fas fa-history"></i>
              <h3 className="font-semibold uppercase text-sm">Activity History</h3>
            </div>
            
            <div className="p-4">
              <p className="text-sm"><span className="font-semibold">{new Date().toLocaleDateString()}</span> - Ethan created customer record.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomersCard;